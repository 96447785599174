import React, { useState, useEffect } from "react";
import Header from "./header";
import Home from "./home";
import About from "./about";
import OngoingProjects from "./ongoing";
import CustomerReviews from "./review";
import Statistics from "./count";
import Video from "./video";
import Contact from "./contact";
import Award from "./awards";
import NavIndicator from "./NavIndicator"; // Import the NavIndicator component

const Index = () => {
    const [currentHeading, setCurrentHeading] = useState('Home');

    const handleScroll = () => {
        const sections = [
            { id: 'home', title: 'Home' },
            { id: 'about', title: 'About' },
            { id: 'ongoing-projects', title: 'Ongoing Projects' },
            { id: 'statistics', title: 'Statistics' },
            { id: 'customer-reviews', title: 'Customer Reviews' },
            { id: 'video', title: 'Video' },
           
            { id: 'contact', title: 'Contact' }
        ];

        for (const section of sections) {
            const element = document.getElementById(section.id);
            const rect = element.getBoundingClientRect();

            if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
                setCurrentHeading(section.title);
                break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <Header title={currentHeading} /> {/* Pass the dynamic heading to the Header */}
            <NavIndicator /> {/* Add the NavIndicator component */}
            <div id="home" className="section">
                <Home />
            </div>
            <div id="about" className="section">
                <About />
            </div>
            <div id="ongoing-projects" className="section">
                <OngoingProjects />
            </div>
            <div id="statistics" className="section">
                <Statistics />
            </div>
            <div id="customer-reviews" className="section">
                <CustomerReviews />
            </div>
            <div id="video" className="section">
                <Video />
            </div>
            {/* <div id="award" className="section">
                <Award />
            </div> */}
            <div id="contact" className="section">
                <Contact />
            </div>
        </div>
    );
};

export default Index;
